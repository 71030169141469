<template>
  <section class="Plateformes">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12 col-md-6 p-0">
          <img src="@/assets/img/flatform_img1.png" class="img-fluid" />
        </div>
        <div class="col-sm-12 col-md-6 pl-50">
          <div class="plateforme-tp-head">
            Superyacht travel with the right impact
          </div>
          <div class="plateforme-tp-content">
            Wolfgang offers a unique service based on innovative digital
            platforms to support the superyacht industry and luxury yacht
            owners.
            <div class="plateforme-tp-content2">
              We optimize every step of the superyacht cruise experience,
              reducing the cost as well as the environmental impact!
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12">
          <img
            src="@/assets/img/2.png"
            class="img-fluid plateforme-solutn-img"
          />
          <div
            class="plateforme-solutn d-flex align-items-center justify-content-center"
          >
            platforms
          </div>
          <div
            class="plateforme-solutn-btm d-flex align-items-center justify-content-center"
          >
            for Yachts and Ports
          </div>
        </div>
      </div>
    </div>
    <div style="position: relative">
      <img
        src="@/assets/img/platform_tp_line.png"
        class="img-fluid plateforme_tp_line"
      />
    </div>
    <div class="solutn-mob">
      <div class="container div-tp-circle">
        <div class="row">
          <div
            class="col-sm-12 col-md-6 pt-sliding-hover solutn-mob-1"
            id="plateformestravels"
          >
            <div class="sliding-tp-head">Wolfgang <br />Yachts</div>
            <div class="sliding-tp-content">
              <span
                >Upgrade your yachting experience with Wolfgang Yachts : the
                <span class="sliding-bold"
                  >all-in-one environmental and cruise management service</span
                >
                for yachts.</span
              >

              <div class="sliding-tp-content2">
                We empower yacht professionals, captains and owners to operate
                more efficient and less impactful cruise and transport
                contracts.
              </div>
              <div class="sliding-tp-content2">
                <span class="sliding-bold"
                  >Reduce operational costs by up to 20%</span
                >
                while minimizing your environmental impact. Our unique platform
                streamlines cruise operational management while our tailored
                service ensures strict compliance, giving you peace of mind on
                every journey!
              </div>
              <btn class="btn sliding-btn" @click="gotowebpro()"
                >learn more</btn
              >
            </div>
          </div>
          <div class="col-sm-12 col-md-6 pt-sliding-hover solutn-mob-2">
            <div class="image-wrapperrtl">
              <img
                src="@/assets/img/platform_slider1.png"
                class="image img-fluid"
                alt="normal"
              />
              <img
                src="@/assets/img/platform_slider2.png"
                class="image-hover img-fluid"
                alt="hover"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="container div-clear">
        <img src="@/assets/img/circle.png" class="img-fluid circle" />
        <div class="row">
          <div class="col-sm-12 col-md-6 pt-sliding-hover solutn-mob-3">
            <div class="image-wrapperltr">
              <img
                src="@/assets/img/platform_slider3.jpg"
                class="image img-fluid"
                alt="normal"
              />
              <img
                src="@/assets/img/platform_slider4.png"
                class="image-hover img-fluid"
                alt="hover"
              />
            </div>
            <div style="position: relative">
              <img
                src="@/assets/img/platform_btm_line.png"
                class="img-fluid plateforme_btm_line"
              />
            </div>
          </div>
          <div class="col-sm-12 col-md-6 pt-sliding-hover solutn-mob-4">
            <div class="sliding-tp-head2">Wolfgang <br />Ports</div>
            <div class="sliding-tp-content">
              <div class="sliding-tp-content2">
                We help ports and marinas manage their wastes and resources and
                reduce their environmental impact.
              </div>
              <div class="sliding-tp-content2">
                Wolfgang Ports is an
                <span class="sliding-bold"
                  >environmental management software</span
                >
                for ports and marina. It is the result of a multi-year and multi
                award-winning R&D project to build an AI-model of the
                environmental impact of boats and use it to allow marina
                operators making data-driven decision and achieve sustainability
                goals.
              </div>

              <button class="btn sliding-btn" aria-disabled="true" disabled>
                coming soon
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "PlateFormes",
  methods: {
    gotowebpro() {
      window.open(process.env.VUE_APP_PRO_WEBSITE_URL, "_blank")
    },
    gotowebtravel() {
      window.open(process.env.VUE_APP_TRAVEL_WEBSITE_URL, "_blank")
    },
  },
}
</script>
