<template>
  <section class="team">
    <div class="container-fluid">
      <div class="row">
        <div
          class="col-md-12 team-fade-down team-1"
          data-aos="fade-down"
          data-aos-duration="3000"
        >
          <div
            class="team-head d-flex align-items-center justify-content-center"
          >
            Founding team
          </div>
        </div>
        <div
          class="col-md-7 team-fade-up team-2"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <img src="@/assets/img/team_frame.png" class="img-fluid team-frame" />
        </div>
        <div
          class="col-md-5 team-fade-left team-3"
          data-aos="fade-left"
          data-aos-duration="3000"
        >
          <div class="team-content">
            <span class="team-content-b"
              >Matthias du Verle and Anthony Flores,</span
            >
            co-founders of Wolfgang are both superyacht captains with careers
            spanning over two decades in the yachting, cruise and merchant
            marine industries. Their close ties with yacht owners and
            passengers, their knowledge of the yachting industry, its legal and
            operational intricacies, made it clear that a complete overall was
            necessary for the benefit of the clients, the industry and the
            planet!
          </div>
          <div class="team-content mt-25">
            Wolfgang aims at creating yacht cruises delivering the best
            experience with the minimum impact.
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "TeamComponent",
  mounted() {
    AOS.init()
  },
}
</script>
<style></style>
