<template>
  <nav class="navbar navbar-expand-sm w-navbar">
    <div class="container-fluid m-0 p-0">
      <div class="row w-100 m-0">
        <div class="col-md-3 nav-p d-flex align-items-center">
          <a class="navbar-brand" href="javascript:void(0)">
            <img src="@/assets/img/logo.png" class="img-fluid" />
          </a>
        </div>
        <div class="col-md-6 nav-p d-flex align-items-center hide-sm-block">
          <div class="collapse navbar-collapse" id="mynavbar">
            <ul class="navbar-nav me-auto">
              <li class="nav-item">
                <router-view
                  ><a class="nav-link" v-scroll-to="'#wolfgang'"
                    >Wolfgang</a
                  ></router-view
                >
              </li>
              <li class="nav-item">
                <router-view
                  ><a class="nav-link" v-scroll-to="'#plateformestravels'"
                    >Products</a
                  ></router-view
                >
              </li>
              <li class="nav-item">
                <router-view
                  ><a class="nav-link" v-scroll-to="'#advanatages'"
                    >Benefits</a
                  ></router-view
                >
              </li>
              <li class="nav-item">
                <router-view
                  ><a class="nav-link" v-scroll-to="'#teamscomponent'"
                    >Team</a
                  ></router-view
                >
              </li>
              <li class="nav-item">
                <router-view
                  ><a class="nav-link" v-scroll-to="'#newscomponent'"
                    >News</a
                  ></router-view
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-3 p-0 hide-sm-block">
          <button
            class="btn float-end header-contact"
            v-scroll-to="'#contactcomponent'"
          >
            <img
              src="@/assets/img/down_arrow_hover.png"
              class="img-fluid contact-icon"
            />
            <img
              src="@/assets/img/down_arrow.png"
              class="img-fluid contact-icon_hover"
            />

            Contact us
          </button>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mynavbar"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
      </div>
    </div>
  </nav>
  <router-view />
  <footer class="w-footer">
    <div class="container-fluid footer-top-content">
      <div class="row">
        <div class="col-md-4 d-flex align-items-center justify-content-center">
          <div class="footer-content" style="text-align: center">
            Funded with the support of the:
            <br />
            Fonds européen de développement régional<br />
            Fondo Europeo di Sviluppo Regionale
          </div>
        </div>
        <div class="col-md-4 d-flex align-items-center justify-content-center">
          <img src="@/assets/InterregLogo.png" class="img-fluid footer-img" />
        </div>
        <div class="col-md-4 d-flex align-items-center justify-content-center">
          <div class="footer-content" style="text-align: center">
            La coopération au coeur de la Méditerranée<br /><br />La
            cooperazione al cuore del Mediterraneo
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-4 d-flex align-items-center justify-content-center">
          <div class="footer-content">
            Wolfgang SAS <br />
            Reg: 843931254<br />
            Nice - France
          </div>
        </div>
        <div
          class="col-md-4 d-flex align-items-center justify-content-center footer-border"
        >
          <img
            src="@/assets/img/footer_logo.png"
            class="img-fluid footer-img"
          />
        </div>
        <div class="col-md-4 d-flex align-items-center justify-content-center">
          <div class="footer-content">+33 489 41 92 14</div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "app",
  created() {
    document.title = "Wolfgang"
  },
}
</script>
