<template>
     <section class="banner">
        <div class="container">
            <div class="row banner">
                <div class="col-sm-12 col-md-6">
                    <div data-aos="fade-right" data-aos-duration="3000">
                        <div class="banner-txt banner-txt-tp">WOLFGANG</div>
                        <div class="banner-txt">
                            <span id="spin"></span>
                        </div>
                        <div class="banner-txt">solutions</div>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="3000"><btn class="btn banner-btn"  v-scroll-to="'#contactcomponent'">Contact us</btn></div>
                </div>

                   <div class="col-sm-12 col-md-6 ">
                    <!--<div class="embed-responsive embed-responsive-1by1 ratio ratio-1x1">
                        <video autoplay="autoplay" muted="muted" class="video-pos  banner-img embed-responsive-item"
                            src="assets/img/mob-hand1.mp4.webm" type="video/mp4"></video>
                    </div>-->
                    <img src="@/assets/img/hand-mob.gif" class="img-fluid" alt="">
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
  name: "BannerComponent",
};
</script>
