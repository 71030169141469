<template>
  <section class="advantages">
    <div class="container-fluid">
      <div class="row mob-hide">
        <div class="col-md-5 col-sm-12 po-rel">
          <div class="advantages-main-head">
            Benefits for the Professionals and the Planet
          </div>
        </div>
        <div class="col-md-3 col-sm-12">
          <div class="advantages_box1">
            <div class="ad-img">
              <img
                src="@/assets/img/advantage-img3.png"
                class="img-fluid advantages_img"
              />
            </div>
            <div class="advantages_head">Yacht Owners</div>
            <div class="advantages_content">
              Manage your superyacht like an asset.<br />Reduce operating costs
              by up to 20%, reduce your environmental impact.<br /><br />
            </div>
          </div>
          <div class="advantages_box2">
            <div class="ad-img">
              <img
                src="@/assets/img/advantage-img1.png"
                class="img-fluid advantages_img"
              />
            </div>
            <div class="advantages_head">Yachting Professionals</div>
            <div class="advantages_content">
              Improve the yield and satisfaction of your clients.
              <br />Remove liabilities and risks, reduce lead time between a
              cruise request and an offer.<br /><br />
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-12 d-flex align-items-center">
          <div class="advantages_box3">
            <div class="ad-img">
              <img
                src="@/assets/img/port_illustration.png"
                class="img-fluid advantages_img"
              />
            </div>
            <div class="advantages_head">Ports and Marinas</div>
            <div class="advantages_content">
              Monitor your environmental status. Evaluate the impact of your
              activity. <br />Monitor administrative and operational management
              of resources and waste.<br /><br />
            </div>
          </div>
        </div>
      </div>
      <div class="row mob-view">
        <div class="advantages-main-head">Benefits for the Professionals and the Planet</div>
        <div>
          <!-- Carousel -->
          <div
            id="advantage-carousel"
            class="carousel slide"
            data-bs-ride="carousel"
          >
            <!-- Indicators/dots -->
            <div class="carousel-indicators">
              <button
                type="button"
                data-bs-target="#advantage-carousel"
                data-bs-slide-to="0"
                class="active"
              ></button>
              <button
                type="button"
                data-bs-target="#advantage-carousel"
                data-bs-slide-to="1"
              ></button>
              <button
                type="button"
                data-bs-target="#advantage-carousel"
                data-bs-slide-to="2"
              ></button>
            </div>

            <!-- The slideshow/carousel -->
            <div class="carousel-inner carousel" id="advantageCarousel">
              <div class="carousel-item active">
                <div class="advantages_box2">
                  <div class="ad-img">
                    <img
                      src="@/assets/img/advantage-img3.png"
                      class="advantages_img"
                    />
                  </div>
                  <div class="advantages_head">Yacht Owners</div>
                  <div class="advantages_content">
                    Manage your superyacht like an asset. Reduce operating costs
                    by up to 20%, reduce your environmental impact.
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="advantages_box2">
                  <div class="ad-img">
                    <img
                      src="@/assets/img/advantage-img1.png"
                      class="advantages_img"
                    />
                  </div>
                  <div class="advantages_head">Yachting Professionals</div>
                  <div class="advantages_content">
                    Improve the yield and satisfaction of your clients. Remove
                    liabilities and risks, reduce lead time between a cruise
                    request and an offer.
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="advantages_box2">
                  <div class="ad-img">
                    <img
                      src="@/assets/img/port_illustration.png"
                      class="advantages_img"
                    />
                  </div>
                  <div class="advantages_head">Ports and Marinas</div>
                  <div class="advantages_content">
                    Monitor your environmental status. Evaluate the impact of
                    your activity. Monitor administrative and operational
                    management of resources and waste.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "AdvantagesComponent",
}
</script>
