<template>
    <section class="news">
        <div class="container-fluid">
          <div> 
            <h1 class="news-head">News</h1>
          </div>    
          <div class="news-container">
            <div class="news-box-wrap owl-carousel">
                <div class="news-box" v-for="(blog, index) in blogdetails" :key="index">
                    <div class="news-box-img" :style="{'background-image': 'url(' + blog._embedded['wp:featuredmedia'][0].source_url + ')'}">
                    </div>
                    <h5><a target="_blank" :href="blog.link">{{ blog.title.rendered }}</a></h5> 
                    <p>{{getPostDate(blog.date)}}</p>
                </div>
            </div> 
          </div> 
        </div>
        
        <div class="d-flex align-items-center justify-content-center news-btm">
        Follow us on our social media channels!
        </div>
          <div class="d-flex justify-content-center align-items-center news-icons">
              <ul class="list-inline">
                  <li class="list-inline-item">
                      <a @click="gotolinkedin()" href="#"><img src="@/assets/img/LinkedIn-Negative.png" class="img-fluid"></a>
                  </li>
                  <li class="list-inline-item">
                      <a @click="gotinsta()" href="#"><img src="@/assets/img/Instagram-Negative.png" class="img-fluid"></a>
                  </li>
                  <li class="list-inline-item">
                      <a @click="gottwitter()" href="#"><img src="@/assets/img/Twitter-Negative.png" class="img-fluid"></a>
                  </li>
              </ul>
          </div>
    </section>
</template>
<script>
import axios from 'axios';
import moment from 'moment';

export default {
  name: "NewsComponent",
  mounted () {
    this.blogRestUrl  = process.env.VUE_APP_BLOG_REST_URL;
          if (this.blogRestUrl) {
            axios
              .get(this.blogRestUrl, { params: { per_page: this.perPage, categories: this.category, _embed: true } })
              .then(response => {
                this.blogdetails = response.data;

              });
          };
   
},
updated(){
  $(".news-box-wrap").owlCarousel({
                items: 3.5,
                slideSpeed: 2500,
                nav: true,
                dots: true,
                responsiveRefreshRate: 200,
                autoplayTimeout:2500,
                margin: 30,
                // loop:true,
                rewind:true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        dots: true
                    },
                    600: {
                        items: 2.5,
                        nav: true,
                        dots: true
                    },
                    1000: {
                        items: 2.5,
                        nav: true,
                        loop: true,
                        dots: true
                    },
                    1200: {
                        items: 3.5,
                        nav: true,
                        loop: false,
                        dots: true
                    }
                }


            });
},
data () {
    return {
      blogdetails: [],
      blogRestUrl:''
    }
  },
   methods: {
    gotolinkedin() {
      window.open(process.env.VUE_APP_LINKEDIN_URL, "_blank");
    },
    gotinsta() {
      window.open(process.env.VUE_APP_INSTA_URL, "_blank");
    },
    gottwitter() {
      window.open(process.env.VUE_APP_TWITTER_URL, "_blank");
    },
    getPostDate (date) {
      return moment(date).format('LL');
    }
  }
}
 
</script>
