<template>
  <BannerComponent />
  <PlateFormes id="wolfgang"/>
  <AdvantagesComponent id="advanatages"/>
  <TeamComponent id="teamscomponent"/>
  <NewsComponent id="newscomponent" />
  <ContactComponent  id="contactcomponent"/>
</template>
<script>
import BannerComponent from "@/components/BannerComponent.vue";
import PlateFormes from "@/components/PlateFormes.vue";
import AdvantagesComponent from "@/components/AdvantagesComponent.vue";
import TeamComponent from "@/components/TeamComponent.vue";
import ContactComponent from "@/components/ContactComponent.vue";
import NewsComponent from "@/components/NewsComponent.vue";

export default {
  name: "HomeView",
  components: {
    BannerComponent,
    PlateFormes,
    AdvantagesComponent,
    TeamComponent,
    ContactComponent,
    NewsComponent
  },
};
</script>