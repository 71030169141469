import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/css/style.css";
import VueScrollTo from 'vue-scrollto';
import VueHoverSlider from 'vue-hover-slider';

const cors = require('cors');

createApp(App).use(store).use(VueScrollTo).use(router).use(VueHoverSlider).mount("#app");
