<template>
 <section class="contact">
        <div class="container-fluid">
            <div class="row">
                <div class="contact-head">Contact us</div>
                <div class="contact-head-content">Do you want to know more about how Wolfgang can help you, or for simple inquiries, please fill up the form.</div>
                <div class="container">
                    <form ref="form" @submit.prevent="sendEmail" class="contact-form contact-form-tp">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="first" class="contact-label">Name</label>
                                    <input type="text" class="form-control contact-placeholder focus"
                                        placeholder="Enter your name" id="first" name="user_name" required> 
                                </div>
                            </div>
                            <!--  col-md-6   -->
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="last" class="contact-label">Company</label>
                                    <input type="text" class="form-control contact-placeholder focus"
                                        placeholder="Enter your company" name="user_company" id="last">
                                </div>
                            </div>
                            <!--  col-md-6   -->
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="first" class="contact-label">Email</label>
                                    <input type="mail" class="form-control contact-placeholder focus"
                                        placeholder="Enter your email" id="first" name="user_email" required>
                                </div>
                            </div>
                            <!--  col-md-6   -->
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="last" class="contact-label">Message</label>
                                    <input type="text" name="user_message" class="lineless-area form-control contact-placeholder focus "
                                        placeholder="Enter your message" required>
                                </div>
                            </div>
                            <!--  col-md-6   -->
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="first" class="contact-label">Phone</label>
                                    <input type="text" class="form-control contact-placeholder focus"
                                        placeholder="Enter your phone" id="first"  name="user_phone">
                                </div>
                            </div>
                            <!--  col-md-6   -->
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="last" class="contact-label"></label>
                                    <input type="text" name="user_additionalinfo" class="form-control contact-placeholder focus" id="last">
                                </div>
                            </div>
                            <!--  col-md-6   -->
                        </div>
                        <div class="text-center">
                            <button type="submit" class="btn contact-form-btn">Send</button>
                        </div>
                        <div v-if="show" style="color: #fff;text-align:center;margin-top:2%;">Thank You!<br/>
We will get in touch shortly</div>
      
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import emailjs from '@emailjs/browser';

export default {
  name: "ContactComponent",
  data () {
    return {
      user_name: '',
      user_company: '',
      user_email: '',
      user_message: '',
      user_phone: '',
      show:false,
      user_additionalinfo: ''
    }
  },
  methods: {
    sendEmail (e) {
      if (this.checked !== '') {
        try {
          emailjs.sendForm(process.env.VUE_APP_EMAILJS_SERVICEID, process.env.VUE_APP_EMAILJS_TEMPLATEID, e.target,
            process.env.VUE_APP_EMAILJS_PUBLICKEY, {
              user_name: this.user_name,
              user_company: this.user_company,
              user_email: this.user_email,
              user_message: this.user_message,
              user_phone: this.user_phone,
              user_additionalinfo: this.user_additionalinfo,
            })
        } catch (error) {
          console.log({ error })
        }
        this.show = true
      }
      // Reset form field
      this.user_name = ''
      this.user_company = ''
      this.user_email = ''
      this.user_message = ''
      this.user_phone = ''
      this.user_additionalinfo = ''
    },
  }

}

</script>